import React, { Component } from 'react';
import NonLayout from "../components/nonLayout";
import Intro from "../components/intro";

class PrivacyPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            pageContent: [],
            ispageLoader: false,
        }
    }

    async componentDidMount() {
        this.getPageDetailsById();
    }

    componentWillReceiveProps(nextProps) {
        this.getPageDetailsById();
    }
    getPageDetailsById() {
        fetch("https://tatva.com/backendapi/api/v1/cms/602b59aa4a831648cdc1383b")
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        ispageLoader: true,
                        pageContent: result.data,
                    });
                },
                (error) => {
                    this.setState({
                        ispageLoader: true,
                        error
                    });
                }
            )
    }

    render() {
        const { pageContent, ispageLoader } = this.state;
        return (
            <NonLayout>
                <Intro
                    title={'Privacy Policy'}
                > </Intro>

                <div className="main-container">
                    <div className="container">
                        <div className="row justify-content-center cms_pages">
                            <div className="col-md-9 col-sm-12">
                                <span dangerouslySetInnerHTML={{ __html: pageContent.description }}></span>
                            </div>
                        </div>
                    </div>
                </div>
            </NonLayout>
        )
    }
}
export default PrivacyPage
